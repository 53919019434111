<template>
  <div>
    <button
      id="buttonPicker"
      v-popover:popover-container.bottom
    >
      Select Icon
    </button>
    <popover
      id="popover-container"
      name="popover-container"
    >
      <font-awesome-picker @selectIcon="iconSelected" />
    </popover>
  </div>
</template>
<script>
import { VueLoggerPlugin } from "@gf/gf-logger";
import { fontAwesomePicker } from "font-awesome-picker";
import Vue from "vue";
import Store from "../../common/store";
import GfContentComponents from "../../GfContentBase";
import Popover from "vue-js-popover";

Vue.use(VueLoggerPlugin);
Vue.use(Popover);
export default {
	name: "IconPickerComponent",
	components: {
		"font-awesome-picker": fontAwesomePicker,
	},

	mixins: [GfContentComponents],

	data() {
		return {
			store: Store,
		};
	},

	mounted() {
		this.$gfLogger.logInfo("Mounted icon picker component");
		const button = document.querySelector("#button");
		const tooltip = document.querySelector("#tooltip");
	},

	methods: {
		iconSelected(selectedIcon) {
			this.$gfLogger.logInfo("selected", selectedIcon);
			window[this.store.state.afterProcessCallBack](selectedIcon);
			$("#buttonPicker").click();
		},
	},
};
</script>
<style scoped>
#buttonPicker {
	font-size: 14px;
	border: transparent;
	cursor: pointer;
	border-radius: 4px;
	padding: 6px;
	align-items: center;
	vertical-align: middle;
}
#popover-container {
	width: 250px !important;
}
</style>
