<script>
import { logger } from "@gf/gf-logger";
import axios from "axios";
import Store from "./store";
import directUploadDemo from "../data/directUploadDemo";

export default {
	name: "UploadServiceMixin",
	props: {
		axiosAjaxOptions: {
			type: Object,
			default: () => {
                return {
                    headers: { 
                        'x-requested-with': 'XMLHttpRequest' 
                    }
                }
            },
		},
	},

	methods: {
        async $_UploadServiceMixin_getDirectUpload(directUpload) {
            logger.logInfo("Sending request to get directUpload:", directUpload);
            if (Store.state.isDemoMode) return directUploadDemo;

            var endpoint = "/upload/get-direct-upload/";

            var res = await axios.post(
                `${Store.state.baseDomain}${endpoint}`,
                directUpload,
                this.axiosAjaxOptions
            );
            logger.logInfo("$_UploadServiceMixin_getDirectUpload Response");
            logger.logInfo(res.data);
            return res.data;
        },

        $_UploadServiceMixin_uploadFile(fileData, fileName, directUpload, typeFile, onLoad, onError) {
			this.$gfLogger.logInfo("$_UploadServiceMixin_uploadFile, fileName:", fileName);
			var key = directUpload.data.SubDirectory + fileName;
			var fd = new FormData();

			fd.append("key", key);
			for (const objKey in directUpload.data.FormData) {
				fd.append(objKey, directUpload.data.FormData[objKey]);
			}
			fd.append("file", fileData);

			var xhr = new XMLHttpRequest();

			xhr.addEventListener('load', () => {
				if (onLoad)
					onLoad();
			});

			xhr.addEventListener('error', (e) => {
				if (onError)
					onError();
			});

			xhr.open("POST", directUpload.data.UploadUrl, true);
			xhr.send(fd);

			return "/" + key;
		},
	},
};
</script>
