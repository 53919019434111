import {
    Node
} from "tiptap";
import {
    wrappingInputRule,
    toggleWrap
} from "tiptap-commands";

export default class NewLine extends Node {
    get name() {
        return "newline";
    }

    get schema() {
        return {
            content: "block*",
            group: "block",
            defining: false,
            draggable: false,
            parseDOM: [{
                tag: "p",
            }],
            toDOM: (node) => {
                return ["p", 0];
            }
        };
    }

    commands({
        type
    }) {
        return (attrs) => (state, dispatch) => {
            const {
                selection
            } = state;
            const position = selection.$cursor ?
                selection.$cursor.pos :
                selection.$to.pos;
            const node = type.create(attrs);
            const transaction = state.tr.insert(position, node);
            dispatch(transaction);
        };
    }
}