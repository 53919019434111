import {
    Node
} from "tiptap";
import {
    wrappingInputRule,
    toggleWrap
} from "tiptap-commands";

export default class TikTok extends Node {
    get name() {
        return "tiktok";
    }

    get schema() {
        return {
            attrs: {
                class: {
                    default: "tiktok-embed"
                },
                cite: {
                    default: ""
                },
                "data-video-id": {
                    default: ""
                },
                title: {
                    default: ""
                },
                src: {
                    default: ""
                }
            },
            content: "block*",
            group: "block",
            defining: true,
            draggable: false,
            parseDOM: [{
                tag: "blockquote",
                getAttrs: (dom) => ({
                    cite: dom.getAttribute("cite")
                })
            }],
            toDOM: (node) => {
                return ["div", {
                        class: "d-flex justify-content-center"
                    },
                    [
                        "blockquote", {
                            class: node.attrs.class,
                            cite: node.attrs.cite,
                            "data-video-id": node.attrs["data-video-id"],
                            style: 'width: 80%; margin: auto;'
                        },
                        ["section", ["p", node.attrs.title]]
                    ]
                ];
            }
        };
    }

    commands({
        type
    }) {
        return (attrs) => (state, dispatch) => {
            const {
                selection
            } = state;
            const position = selection.$cursor ?
                selection.$cursor.pos :
                selection.$to.pos;
            const node = type.create(attrs);
            const transaction = state.tr.insert(position - 1, node);
            dispatch(transaction.scrollIntoView());
        };
    }

    keys({
        type
    }) {
        return {
            "Ctrl->": toggleWrap(type)
        };
    }

    // inputRules({ type }) {
    //   return [wrappingInputRule(/^\s*>\s$/, type)];
    // }
}