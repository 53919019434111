import {
    Node
} from "tiptap";

export default class DailyMotion extends Node {
    get name() {
        return "dailymotion";
    }

    get schema() {
        return {
            attrs: {
                src: {
                    default: null
                },
                frameborder: {
                    default: 0
                },
                allowfullscreen: {
                    default: 'true'
                },
                allow: {
                    default: 'fullscreen'
                },
                width: {
                    default: '80%'
                },
                height: {
                    default: '315'
                },
                referrerpolicy: {
                    default: "no-referrer-when-downgrade"
                },
                id: {
                    default: 'dailymotion-frame-0'
                }
            },
            group: "block",
            selectable: false,
            parseDOM: [{
                tag: "iframe[src]",
                getAttrs: (dom) => ({
                    src: dom.getAttribute("src"),
                })
            }],
            toDOM: (node) => [
                "div", {
                    class: 'd-flex justify-content-center'
                },
                ["iframe", node.attrs]
            ]
        };
    }

    commands({
        type
    }) {
        return (attrs) => (state, dispatch) => {
            const {
                selection
            } = state;
            const position = selection.$cursor ? selection.$cursor.pos : selection.$to.pos;
            const node = type.create(attrs);
            const transaction = state.tr.insert(position - 1, node);
            dispatch(transaction.scrollIntoView());
        };
    }
}