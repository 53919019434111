<template>
  <div class="container-fluid px-0">
    <div
      v-if="error"
      class="error-container px-3"
    >
      <div class="alert alert-danger">
        {{ error }}
      </div>
    </div>
    <div class="upload-content p-4">
      <p class="uploader-title">
        Blog Attachments:
      </p>

      <div
        class="advance-uploader"
      >
        <vue-dropzone
          id="dropzone"
          ref="dropzone"
          :destroy-dropzone="true"
          :options="dropzoneOptions"
          :include-styling="false"
          :use-custom-slot="customSlot"
          @vdropzone-thumbnail="thumbnail"
          @vdropzone-success="vfileUploaded"
          @vdropzone-processing="dropzoneChangeUrl"
          @vdropzone-removed-file="vdropzoneRemovedFile"
        >
          <div class="dropzone-custom-content">
            <h5 class="dropzone-custom-title">
              Drag and drop to upload image!
            </h5>
            <div class="subtitle">
              ...or click to select an image from your computer
            </div>
          </div>
        </vue-dropzone>
      </div>
    </div>
  </div>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import Store from "../../common/store";
import UploadServiceMixin from "../../common/UploadServiceMixin.vue";
import { Editor } from "tiptap";
import GfContentComponents from "../../GfContentBase";

export default {
  name: 'UploadImage',

  components: {
    vueDropzone: vue2Dropzone,
  },
  mixins: [GfContentComponents, UploadServiceMixin],
  props: {
    editor: {
      type: Editor,
      default: null
    }
  },

  data() {
    return {
      // show: true,
      error: '',
      customSlot: true,
      imageCount: 0,
      isEmpty: true,
      uploadEndpoint: '/upload/get-direct-upload/',
      dropzoneOptions: {
        url: "https://httpbin.org/post",
        dictFileTooBig: 'Image too large',
        dictCancelUpload: 'Cancel',
        thumbnailWidth: 120,
        thumbnailHeight: 120,
        maxFiles: Store.state.componentSettings.maxFiles,
        maxFilesize: Store.state.componentSettings.maxFileSize,
        addRemoveLinks: false,
        previewTemplate: this.template(),
        uploadMultiple: true,
      },
      uploadResult: [], 
      temporaryFileName: ''
    }
  },

  watch: {
    imageCount() {
      if(this.imageCount >= 1) {
        this.isEmpty = false;
        this.customSlot = false;
      } else {
        this.isEmpty = true;
        this.customSlot = true;
      }
    }
  },

  methods: {

    clearFile() {
      this.isEmpty = true;
      this.$refs.file.value=null;
      this.basicUpload.fileName = 'Choose a file…'
    },

    vdropzoneRemovedFile(file, error, xhr) {
      this.imageCount--;
    },

    dropzoneChangeUrl(file) {
      // SET DYNAMIC URL
      const uploadUrl =  `${Store.state.baseDomain}${this.uploadEndpoint}`;
      const demo = Store.state.isDemoMode;

      this.uploadProcess(file, file.type);
    },

    vfileUploaded(file) {
      this.isEmpty = false;
      this.imageCount++;
    },

    insertImage(file, fileType, fileName) {

      let selectedFile = this.uploadResult.find(res => fileName === res.originalFileName);

      this.error = '';

      if ( ! selectedFile) {
        this.error = 'Unable to find the reference to the file that you are trying to insert.';
        return;
      }

      this.editor.commands.image({
        type: "Image",
        src: $('#' + selectedFile.fileName.split('.').shift()).html(),
        style: 'display:block; max-height: 200px; margin:0px auto;'
      });
      this.editor.commands.newline();

      this.$modal.hide('upload-modal');
    },

		closeModal() {
      this.advancedUploader = true;
      this.show = false;
      this.$modal.hide('upload-modal');
    },

    template: function () {
        return `<div class="dz-preview dz-file-preview">
                <div class="dz-image">
                    <img data-dz-thumbnail-bg />
                </div>
                <div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress></span></div>
                <div class="dz-error-message"><span data-dz-errormessage></span></div>
                <a class="dz-insert" data-dz-insert href="#">Insert</a>
                <i class="fa fa-times-circle remove-btn" aria-hidden="true" data-dz-remove></i>
                <span uploaded-image-name class='dz-image-path' style="display:none;"></span>
            </div>
        `;
    },

    getMobileOperatingSystem() {
      const userAgent = navigator.userAgent;

      if (/android/i.test(userAgent)) {
        return 'android';
      }
      if (/iPad|iPhone|iPod/i.test(userAgent)) {
        return 'ios'
      }
    },

    async rotateImage(base64data) {
      return await new Promise(resolve => {
        var canvas = document.createElement('canvas');
        var ctx = canvas.getContext("2d");
        var image = new Image();
        
        image.onload = () => {
          ctx.rotate(-90 * Math.PI / 180);
          ctx.translate(-image.width, image.height);
          ctx.drawImage(image, 0, 0);
          resolve(canvas.toDataURL("image/png"));
        }
        image.src = base64data;
      })
    },

    thumbnail: async function(file, dataUrl) {
      var j, len, ref, thumbnailElement, x, imageNameElement;
      var vm = this;
      if (file.previewElement) {
        file.previewElement.classList.remove("dz-file-preview");
        ref = file.previewElement.querySelectorAll("[data-dz-thumbnail-bg]");
        x = file.previewElement.querySelectorAll("[data-dz-insert]");
        imageNameElement = file.previewElement.querySelectorAll("[uploaded-image-name]");
        for (j = 0, len = ref.length; j < len; j++) {
          thumbnailElement = ref[j];
          thumbnailElement.alt = file.name;
          thumbnailElement.src = this.getMobileOperatingSystem() == 'android' ? await this.rotateImage(dataUrl) : dataUrl;
          imageNameElement[j].id = this.temporaryFileName.split('.').shift();
          x[j].addEventListener('click', () => {
            vm.insertImage(dataUrl, file.type, file.name);
          });
        }
        return setTimeout(function() {
          return file.previewElement.classList.add("dz-image-preview")
        }, 1);
      }
    },

    async uploadProcess(fileData, typeFile) {
      var data = {
				Context: {
					FundraisingPageId: Store.state.fundraisingPageId,
				},
				Key: Store.state.configurationKey,
				UserId: Store.state.userId,
				ContentType: typeFile
      };

      const now = new Date();
      var extension = null;

      if (typeFile == "image/jpeg") {
				extension = ".jpg";
			} else if (typeFile == "image/png") {
				extension = ".png";
			} else if (typeFile == "image/gif") {
				extension = ".gif";
			}

      this.error = '';

      if (extension == null) {
        this.error = 'Unable to insert file. Only images are allowed.';
        this.$refs.dropzone.removeFile(fileData);
        return;
      }

      var tempFileName = "blog-image-" + this.fileTimeFromDate(now) + extension;
      this.temporaryFileName = tempFileName;
      var directUploadResult = await this.$_UploadServiceMixin_getDirectUpload(data);

      var processResult = {
        success: directUploadResult.success,
        savedPath: ``,
        originalFileName: fileData.name,
        fileName: tempFileName,
        fileData: fileData,
        fundraisingPageId: Store.state.fundraisingPageId,
        eventCampaignId: Store.state.eventCampaignId,
        formId: Store.state.formId,
        userId: Store.state.userId
      };

      this.uploadResult.push(processResult);

      this.$gfLogger.logInfo('processResult', processResult);

      if (directUploadResult.success == true) {

				if (directUploadResult.data.AccessKey) {
					processResult.savedPath = await this.$_UploadServiceMixin_uploadFile(
						fileData,
						tempFileName,
						directUploadResult,
						typeFile,
            this.onUploadLoad,
            this.onUploadError
          );

          // Update the saved path with the real path uploaded on the server
          this.uploadResult = this.uploadResult.map(res => {
            if (res.originalFileName === processResult.originalFileName) {
              res.savedPath = processResult.savedPath;
            }
            return res;
          });

				} else {
          // Remove the file if there's an error
          this.$refs.dropzone.removeFile(fileData);
        }

        if (typeof window[this.afterProcessCallBack] != 'undefined') {
          window[this.afterProcessCallBack](processResult);
        }
        
			} else {
        // Remove the file if there's an error
        this.$refs.dropzone.removeFile(fileData);
      }
    },

    onUploadError(e){
        console.error('uploadFile.error', e);
        this.$refs.dropzone.removeFile(fileData);
    }, 

    onUploadLoad(e){
      return new Promise(resolve => {
        resolve("/" + e);
      })
    },

    dataURItoBlob(dataURI) {
			var byteString;
			if (dataURI.split(",")[0].indexOf("base64") >= 0)
				byteString = atob(dataURI.split(",")[1]);
			else byteString = unescape(dataURI.split(",")[1]);

			var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

			var ia = new Uint8Array(byteString.length);
			for (var i = 0; i < byteString.length; i++) {
				ia[i] = byteString.charCodeAt(i);
			}

			return new Blob([ia], { type: mimeString });
		},

    fileTimeFromDate(date) {
			return date.getTime() * 1e4 + 116444736e9;
		},

  },

}
</script>

<style>
@import url("https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css");

.uploader-title {
  font-size: 12px;
  font-weight: 600;
}

/* **
  BUTTON
*/

#upload-image button {
  font-size: 100%;
  padding: 0.5em 1em;
  color: white;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  border: 1px solid #999;
  border: transparent;
  text-decoration: none;
  border-radius: 50px;
  cursor: pointer;
}

#upload-image button.danger {
  background: rgb(202, 60, 60);
}

#upload-image button.success {
  background: rgb(28, 184, 65);
}

#upload-image button:disabled {
  opacity: 0.3;
  pointer-events: none;
}

#upload-image button + button {
  margin-left: 10px;
}

/***
  MODAL WRAPPER / CONTENT / FOOTER
*/

#upload-image .upload-content {
  background-color: #fff;
  padding: 30px;
  border-radius: 6px;
}

.upload-footer {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  border-top: none;
}

/***
    CLOSE BTN
*/

.close-btn {
  color: #ff5959;
  position: absolute;
  right: 20px;
  top: 15px;
  cursor: pointer;
  font-size: 18px;
}



/***
  VUE DROPZONE
*/

.advance-uploader {
  margin-top: 20px;
}

#dropzone {
  border: 2px dashed #f3f3f3;
  text-align: center;
}

/* Insert button */
#dropzone .dz-insert {
  font-size: 11px !important;
  border-radius: 50px !important;
  font-weight: 300 !important;
  background: #28a745 !important;
  border-color: #28a745 !important;
  padding: 5px 11px !important;
  text-decoration: none !important;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
  margin-bottom: 11px;
  color: #fff !important;
  -webkit-transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
  transition: all ease-in-out 0.3s;
}

#dropzone .dz-insert:hover {
  color: #fff !important;
  opacity: 0.8;
}

/* Remove button */
#dropzone .dz-remove {
  display: none;
  font-size: 11px !important;
  border-radius: 50px !important;
  font-weight: 300 !important;
  background: red !important;
  border-color: red !important;
  padding: 5px 5px !important;
  text-decoration: none !important;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
  margin-bottom: 11px;
  color: #fff !important;
  -webkit-transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
  transition: all ease-in-out 0.3s;
}

#dropzone .remove-btn {
  color: red;
  right: 13px;
  top: 3px;
  position: absolute;
  cursor: pointer;
  -webkit-transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
  transition: all ease-in-out 0.3s;
}

#dropzone .remove-btn:hover {
  opacity: 0.5;
}

#dropzone .dz-remove:hover {
  color: #fff !important;
  opacity: 0.8;
}

#dropzone .dz-details {
  display: none;
}

#dropzone .dz-preview .dz-success-mark svg,
#dropzone .dz-preview .dz-error-mark svg {
  display: block;
  width: 25px;
  height: 25px;
  margin-top: -25px;
}

#dropzone .dz-preview .dz-progress .dz-upload {
  background: #2196f3;
}

#dropzone .dz-progress {
  margin-top: -25px !important;
}

.dropzone .dz-preview.dz-error:hover .dz-error-message {
  top: 87%;
  font-size: 10px;
  border-radius: 4px;
  background: #c32d2d;
  padding: 10px 5px;
}

#dropzone {
  transition: background-color .2s linear;
  padding: 23px;
  max-height: 300px;
  overflow-y: auto;
}

#dropzone .dz-preview {
  width: 140px;
  display: inline-block;
  margin-bottom: 20px;
  position: relative;
  text-align: center;
  margin: 20px 11px;
}
#dropzone .dz-preview .dz-image {
  width: 120px;
  height: 120px;
  margin-bottom: 10px;
  margin-left: 10px;
  text-align: center;
}
#dropzone .dz-preview .dz-image > div {
  width: inherit;
  height: inherit;
  background-size: contain;
}
#dropzone .dz-preview .dz-image > img {
  width: 100%;
}

#dropzone .dz-preview .dz-details {
  color: white;
  transition: opacity .2s linear;
  text-align: center;
}
#dropzone .dz-success-mark, .dz-error-mark {
  display: none;
}

.dropzone-custom-content {
  text-align: center;
}

.dropzone-custom-content h5 {
  font-size: 1rem;
}

.dropzone-custom-content .subtitle {
  font-size: 0.8rem;
}

</style>
