import { logger } from "@gf/gf-logger";

export default {
    state: {
        isDemoMode: false,
        baseDomain: "",
        fundraisingPageId: null,
        userId: null,
        configurationKey: null,
        afterProcessCallBack: "",
        componentMode: null,
        componentSettings: null,
    },

    setBaseDomain(baseDomain) {
        this.state.baseDomain = baseDomain;
        logger.logInfo(`Store.setBaseDomain(): ${baseDomain}`);
    },

    enableDemoMode() {
        this.state.isDemoMode = true;
        logger.logInfo("Store.enableDemoMode(): enabling demo mode");
    },

    setFundraisingPageId(frpId) {
        this.state.fundraisingPageId = frpId;
        logger.logInfo(`Store.setFundraisingPageId(): ${frpId}`);
    },

    setUserId(userId) {
        this.state.userId = userId;
        logger.logInfo(`Store.setUserId(): ${userId}`);
    },

    setConfigurationKey(configurationKey) {
        this.state.configurationKey = configurationKey;
        logger.logInfo(`Store.setConfigurationKey(): ${configurationKey}`);
    },

    setAfterProcessCallBack(afterProcessCallBack) {
        this.state.afterProcessCallBack = afterProcessCallBack;
        logger.logInfo(`Store.setAfterProcessCallBack(): ${afterProcessCallBack}`);
    },
    setEventCampaignId(eventCampaignId) {
        this.state.eventCampaignId = eventCampaignId;
        logger.logInfo(`Store.setEventCampaignId(): ${eventCampaignId}`);
    },
    setFormId(formId) {
        this.state.formId = formId;
        logger.logInfo(`Store.setFormId(): ${formId}`);
    },
    setComponentMode(mode, settings) {
        this.state.componentMode = mode;
        if (settings && settings !== {})
            this.state.componentSettings = settings;
        logger.logInfo(`Setting mode to ${mode}, with settings:`, settings);
    },

}   