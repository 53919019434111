import axios from "axios";
import logger from "./logger";
import Store from "./store";
import publishContentDemo from "../data/publishContentDemo";
import updateContentDemo from "../data/updateContentDemo";

export default {
    axiosAjaxOptions: {
        headers: {'x-requested-with': 'XMLHttpRequest'}
    },

    async publishContent(data) {
        if (Store.state.isDemoMode) return publishContentDemo["SUCCESS"];

        var endpoint = `/content/publish`;

        var res = await axios.post(
            `${Store.state.baseDomain}${endpoint}`,
            data,
            this.axiosAjaxOptions
        );
        logger.logInfo("ContentService.publishContent(), response", res.data);
        return res.data;
    },

    async updateContent(data) {
        if (Store.state.isDemoMode) return updateContentDemo["SUCCESS"];

        var endpoint = `/content/update`;

        var res = await axios.post(
            `${endpoint}`,
            data,
            this.axiosAjaxOptions
        );

        return res;
    },
};
