<template>
  <div class="editor">
    <editor-floating-menu
      v-if="enableContentOptions"
      v-slot="{ commands, isActive, menu }"
      :editor="editor"
    >
      <div
        class="editor__floating-menu light-theme"
        :class="{ 'is-active': menu.isActive }"
        :style="`top: ${menu.top}px`"
      >
        <button
          class="mx-1 btn btn-sm"
          :class="{ 'btn-active': editor.activeMarkAttrs.aligntext.align === 'left' }"
          @click="commands.aligntext({ align: 'left' })"
        >
          <font-awesome-icon :icon="alignleftIcon" />
        </button>

        <button
          class="mx-1 btn btn-sm"
          :class="{ 'btn-active': editor.activeMarkAttrs.aligntext.align === 'center' }"
          @click="commands.aligntext({ align: 'center' })"
        >
          <font-awesome-icon :icon="aligncenterIcon" />
        </button>

        <button
          class="mx-1 btn btn-sm"
          :class="{ 'btn-active': editor.activeMarkAttrs.aligntext.align === 'right' }"
          @click="commands.aligntext({ align: 'right' })"
        >
          <font-awesome-icon :icon="alignrightIcon" />
        </button>

        <button
          class="mx-1 btn btn-sm"
          :class="{ 'btn-active': editor.activeMarkAttrs.aligntext.align === 'justify' }"
          @click="commands.aligntext({ align: 'justify' })"
        >
          <font-awesome-icon :icon="alignjustifyIcon" />
        </button>

        <button
          class="mx-1 btn btn-sm"
          :class="{ 'btn-active': isActive.heading({ level: 1 }) }"
          @click="commands.heading({ level: 1 })"
        >
          H1
        </button>

        <button
          class="mx-1 btn btn-sm"
          :class="{ 'btn-active': isActive.heading({ level: 2 }) }"
          @click="commands.heading({ level: 2 })"
        >
          H2
        </button>

        <button
          class="mx-1 btn btn-sm"
          :class="{ 'btn-active': isActive.heading({ level: 3 }) }"
          @click="commands.heading({ level: 3 })"
        >
          H3
        </button>

        <button
          class="mx-1 btn btn-sm"
          :class="{ 'btn-active': isActive.bullet_list() }"
          @click="commands.bullet_list"
        >
          <font-awesome-icon :icon="ulListIcon" />
        </button>

        <button
          class="mx-1 btn btn-sm"
          :class="{ 'btn-active': isActive.ordered_list() }"
          @click="commands.ordered_list"
        >
          <font-awesome-icon :icon="olListIcon" />
        </button>

        <button
          class="mx-1 btn btn-sm"
          :class="{ 'btn-active': isActive.blockquote() }"
          @click="commands.blockquote"
        >
          <font-awesome-icon :icon="quoteRIcon" />
        </button>
        <button
          class="mx-1 btn btn-sm"
          @click="openEmbedModal"
        >
          <font-awesome-icon :icon="hashtagIcon" />
        </button>
      </div>
    </editor-floating-menu>

    <editor-menu-bubble
      v-if="enableContentOptions"
      v-slot="{ commands, isActive, menu, getMarkAttrs }"
      :editor="editor"
    >
      <div
        class="menububble dark-theme"
        :class="{ 'is-active': menu.isActive }"
        :style="`left: ${menu.left}px; bottom: ${menu.bottom}px;`"
      >
        <button
          class="mx-1 btn btn-sm"
          :class="{ 'btn-active': isActive.bold() }"
          @click="commands.bold"
        >
          <font-awesome-icon :icon="boldIcon" />
        </button>

        <button
          class="mx-1 btn btn-sm"
          :class="{ 'btn-active': isActive.italic() }"
          @click="commands.italic"
        >
          <font-awesome-icon :icon="italicIcon" />
        </button>

        <button
          class="mx-1 btn btn-sm"
          :class="{ 'btn-active': isActive.underline() }"
          @click="commands.underline"
        >
          <font-awesome-icon :icon="underlineIcon" />
        </button>

        <button
          class="mx-1 btn btn-sm"
          :class="{ 'btn-active': isActive.strike() }"
          @click="commands.strike"
        >
          <font-awesome-icon :icon="strikeIcon" />
        </button>

        <form
          v-if="linkMenuIsActive"
          class="menububble__form"
          @submit.prevent="setLinkUrl(commands.link, linkUrl)"
        >
          <input
            ref="linkInput"
            v-model="linkUrl"
            class="menububble__input"
            type="text"
            placeholder="https://"
            @keydown.esc="hideLinkMenu"
          >
          <button
            class="mx-1 btn btn-sm menububble__button"
            type="button"
            @click="setLinkUrl(commands.link, null)"
          >
            <font-awesome-icon :icon="closeIcon" />
          </button>
        </form>

        <template v-else>
          <button
            class="mx-1 btn btn-sm"
            :class="{ 'btn-active': isActive.link() }"
            @click="showLinkMenu(getMarkAttrs('link'))"
          >
            <font-awesome-icon :icon="linkIcon" />
            <span class="text-btn">{{ isActive.link() ? 'Update Link' : 'Add Link' }}</span>
          </button>
        </template>
      </div>
    </editor-menu-bubble>
    <div class="row">
      <div
        class="col-md-12"
        :class="{'col-11': !isMultiLine}"
      >
        <editor-content
          class="content-editor editor__content"
          :class="{ 'custom-content-height': height > 0 }"
          :style="computedHeight"
          :editor="editor"
        />
      </div>
      <div

        v-if="!isMultiLine"
        class="px-0 d-md-none col-1 pointer"
      >
        <font-awesome-icon
          :icon="sendIcon"
          class="h3 heading-3 send-icon gf-driver-text"
          @click="postContent"
        />
      </div>
    </div>
    <div class="row py-2">
      <EmbedSocial
        ref="SocialEmbedModal"
        :editor="editor"
      />
      <UploadImage
        ref="ytmodal"
        :editor="editor"
      />
      <div class="d-none d-md-block col-md-6" />
      <div class="col-md-6 text-right">
        <div
          class="col-6 btn text-secondary btn-cancel-post text-right"
          @click="cancelPost"
        >
          Cancel
        </div>
        <div
          class="d-md-inline-block col-6 gf-driver-button btn gf-driver-button-rounded px-0 btn-post-content text-capitalize"
          :class="{'d-none': !isMultiLine}"
          @click="postContent"
        >
          {{ postButtonText }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Logger from "../../common/logger";
import { Editor, EditorContent, EditorFloatingMenu, EditorMenuBubble } from "tiptap";
import {
  Bold,
  ListItem,
  Link,
  Italic,
  Heading,
  Blockquote,
  BulletList,
  OrderedList,
  Strike,
  Underline,
  AlignText,
  Image,
  HardBreak,
  Iframe,
  Youtube,
  Vimeo,
  TikTok,
  Twitter,
  DailyMotion,
  NewLine
} from "./TipTapExtensions/GfTipTapExtensions";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faChevronCircleRight,
  faBold,
  faItalic,
  faLink,
  faQuoteRight,
  faListUl,
  faListOl,
  faTimesCircle,
  faStrikethrough,
  faUnderline,
  faAlignLeft,
  faAlignRight,
  faAlignCenter,
  faAlignJustify,
  faImage,
  faHashtag
} from "@fortawesome/free-solid-svg-icons";

import UploadImage from '../Upload/UploadImage';
import EmbedSocial from '../Embed/Social';

export default {
    name: "ContentPost",
    components: {
        EditorContent,
        EditorFloatingMenu,
        EditorMenuBubble,
        FontAwesomeIcon,
        UploadImage,
        EmbedSocial
    },
    props: {
        enableContentOptions: {
            type: Boolean,
            default: true
        },
        characterLimit: {
            type: Number,
            default: 0
        },
        postButtonText: {
            type: String,
            default: "Comment"
        },
        height: {
            type: Number,
            default: 0
        },
        defaultContent: {
            type: String,
            default: "Thank you"
        }
    },
    data() {
        return {
            keepInBounds: true,
            editor: null,
            config: {},
            content: null,
            sendIcon: faChevronCircleRight,
            boldIcon: faBold,
            italicIcon: faItalic,
            linkIcon: faLink,
            quoteRIcon: faQuoteRight,
            ulListIcon: faListUl,
            olListIcon: faListOl,
            closeIcon: faTimesCircle,
            strikeIcon: faStrikethrough,
            underlineIcon: faUnderline,
            alignleftIcon: faAlignLeft,
            alignrightIcon: faAlignRight,
            aligncenterIcon: faAlignCenter,
            alignjustifyIcon: faAlignJustify,
            imageIcon: faImage,
            hashtagIcon: faHashtag,
            linkUrl: null,
            linkMenuIsActive: false,
        };
    },
    computed: {
        isMultiLine() {
            return this.height > 0;
        },

        computedHeight() {
            return `--customHeight: ${this.height}px`;
        }
    },

    mounted() {
        this.editor = new Editor({
            extensions: [
                new Bold(),
                new ListItem(),
                new Link(),
                new Italic(),
                new Heading({ levels: [1, 2, 3] }),
                new Blockquote(),
                new BulletList(),
                new OrderedList(),
                new Strike(),
                new Underline(),
                new AlignText(),
                new Image(),
                new HardBreak(),
                new Iframe(),
                new Youtube(),
                new Vimeo(),
                new TikTok(),
                new Twitter(),
                new DailyMotion(),
                new NewLine()
            ],
            onUpdate: this.onEditorUpdate,
            content: this.defaultContent
        });

        this.content = {
            html: this.editor.getHTML(),
            json: this.editor.getJSON()
        };
    },
    beforeDestroy() {
        this.editor.destroy();
    },
    methods: {

        onEditorUpdate(state) {
          Logger.logInfo(
              "ContentPost.onEditorUpdate() - Original: " + state.getHTML()
          );
          if (
              this.characterLimit > 0 &&
              state.getHTML().length > this.characterLimit
          )
              this.editor.setContent(
                  state.getHTML().substring(0, this.characterLimit)
              );

          Logger.logInfo(
              "ContentPost.onEditorUpdate() - Final: " + state.getHTML()
          );
          this.content = {
              html: state.getHTML(),
              json: state.getJSON()
          };
        },

        hideLinkMenu() {
            this.linkUrl = null;
            this.linkMenuIsActive = false;
        },

        showLinkMenu(attrs) {
            this.linkUrl = attrs.href;
            this.linkMenuIsActive = true;
            this.$nextTick(() => {
                this.$refs.linkInput.focus();
            });
        },

        setLinkUrl(command, url) {
            command({ href: url });
            this.hideLinkMenu();
        },

        postContent() {
            Logger.logInfo(this.content);
            this.$emit("submit", this.content);
        },

        cancelPost() {
            this.$emit("cancel");
        },

        openEmbedModal() {
          this.$refs.SocialEmbedModal.$modal.show('embed-modal');
        },
    }
};
</script>

<style lang="scss">

.dark-theme {
  background: #171616;

  .btn {
    color: #fff;
  }

  svg {
    path {
      fill: #fff !important;
    }
  }

  .btn-active {
    background:#5f5f5f !important;
  }

  .menububble {
    background: #000;
  }

  .menububble__input {
    background: #211e1e;
    border: none;
    padding: 3px 10px;
    color: #b7b7b7;

    &:focus {
      outline: none;
    }
  }
}

.light-theme {
  background: transparent;

  .btn {
    color: #000;
  }

  svg {
    path {
      fill: #000 !important;
    }
  }

  .btn-active {
    background:#5f5f5f !important;
  }

  .menububble {
    background: transparent;
  }

  .menububble__input {
    background: #211e1e;
    border: none;
    padding: 3px 10px;
    color: #000;

    &:focus {
      outline: none;
    }
  }
}

.menububble,
.editor__floating-menu {
  left: 50px;

  .btn {
    background: transparent;
    padding: 1px 5px;
    border: none;
    border-radius: 2px;
    margin: 2px 2px !important;
    font-weight: 600;
    font-size: 14px;
  }
}

.menububble,
.editor__floating-menu.is-active {
  border-radius: 5px;
  padding: 2px
}

.editor__content blockquote {
  border-left: 3px solid rgba(0,0,0,.1);
  color: rgba(0,0,0,.8);
  padding-left: .8rem;
  font-style: italic;
}

.editor__content pre {
  padding: .7rem 1rem;
  border-radius: 5px;
  background: #000;
  color: #fff;
  font-size: .8rem;
  overflow-x: auto;
}

.editor {
  position: relative;
  &__floating-menu {
    position: absolute;
    z-index: 1;
    margin-top: -0.25rem;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.2s, visibility 0.2s;
    &.is-active {
      opacity: 1;
      visibility: visible;
    }

    &:focus {
      outline: none !important;
    }
  }
}

/**
  LINK EXTENTION
*/

.text-btn {
  margin-left: 5px;
}


/**
  MENU BUBBLE
*/

.menububble {
  position: absolute;
  display: -webkit-box;
  display: flex;
  z-index: 20;
  border-radius: 5px;
  padding: .3rem;
  margin-bottom: .5rem;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  visibility: hidden;
  opacity: 0;
  -webkit-transition: opacity .2s,visibility .2s;
  transition: opacity .2s,visibility .2s;
}

.menububble__form {
  display: flex;
}

.menububble.is-active {
  opacity: 1;
  visibility: visible;
}

.content-editor .ProseMirror {
  border: 1px solid #aaa;
  padding-left: 0.5em;
  padding-right: 0.5em;
  padding-top: 0.2em;
  border-radius: 10px;
  min-height: 35px;
}

.send-icon {
  min-height: 35px;
}

.content-editor .ProseMirror p {
  margin-bottom: 0px;
}
.pointer {
  cursor: pointer !important;
}

.custom-content-height .ProseMirror {
  height: var(--customHeight);
  overflow-y: scroll;
}

.editor button.mx-1.btn.btn-sm {
    display: inline-block !important;
    width: auto !important;
}

</style>
