import {
    Node
} from "tiptap";
import {
    wrappingInputRule,
    toggleWrap
} from "tiptap-commands";

export default class Twitter extends Node {
    get name() {
        return "twitter";
    }

    get schema() {
        return {
            attrs: {
                class: {
                    default: "twitter-tweet"
                },
                src: {
                    default: ""
                },
                id: {
                    default: 'twitter-tweet-0'
                },
            },
            content: "block*",
            group: "block",
            defining: true,
            draggable: false,
            parseDOM: [{
                tag: "div",
            }],
            toDOM: (node) => {
                return ["div", {
                    id: `twitter-tweet-${node.attrs.id}`,
                    style: 'width: 80%; margin: auto; height: auto;'
                }];
            }
        };
    }

    commands({
        type
    }) {
        return (attrs) => (state, dispatch, view) => {
            const {
                selection
            } = state;
            const position = selection.$cursor ?
                selection.$cursor.pos :
                selection.$to.pos;
            const node = type.create(attrs);
            const hard_break = node.hard_break;
            const transaction = state.tr.insert(position - 1, node);
            dispatch(transaction.scrollIntoView());
        };
    }
}