<template lang="html">
  <div
    id="vue-gf-content-components"
    class="container"
  >
    GoFundraise Web Component Template
  </div>
</template>

<script>
import Vue from "vue";
import { VueLoggerPlugin } from "@gf/gf-logger";
import Store from "./common/store";


Vue.use(VueLoggerPlugin);

export default {
	name: "GfContentComponents",
	components: {

	},

	props: {
		userId: {
			type: Number,
			default: 0,
		},
		fundraisingPageId: {
			type: Number,
			default: 0,
		},
		eventCampaignId: {
			type: Number,
			default: 0,
		},
		formId: {
			type: Number,
			default: 0,
		},
		baseDomain: {
			type: String,
			default: "",
		},
		demo: {
			type: Boolean,
			default: false,
		},
		configurationKey: {
			type: String,
			default: "",
		},
		inputDisplayIdSelector: {
			type: String,
			default: "",
		},
		inputDisplayClassSelector: {
			type: String,
			default: "",
		},
		afterProcessCallBack: {
			Type: String,
			default: "",
		},
		loadInModal: {
			type: Boolean,
			default: false,
		},
		mode: {
			type: Object,
			default: () => {}
		}
	},

	data() {
		return {
			store: Store,
		};
	},

	computed: {
		componentToLoad() {
			if (!this.componentMode || this.componentMode == '')
                return null;

            return gfComponents.LoadComponent(this.componentMode);
		},
	},

	mounted: function () {
		this.$gfLogger.logInfo("Mounted GfContentComponents");

		if (this.demo) this.store.enableDemoMode();

		if (this.baseDomain && this.baseDomain.length > 0)
			this.store.setBaseDomain(this.baseDomain);

		if (this.fundraisingPageId && this.fundraisingPageId > 0)
			this.store.setFundraisingPageId(this.fundraisingPageId);

		if (this.userId && this.userId > 0) this.store.setUserId(this.userId);

		if (this.configurationKey && this.configurationKey.length > 0){
			this.store.setConfigurationKey(this.configurationKey);
		}
		else{
			this.configurationKey = this.store.state.configurationKey
		}

		if (this.afterProcessCallBack && this.afterProcessCallBack.length > 0){
			this.store.setAfterProcessCallBack(this.afterProcessCallBack);
			this.afterProcessCallBackFunction = this.afterProcessCallBack;
		}
		else{
			this.afterProcessCallBack = this.store.state.afterProcessCallBack
		}

		if (this.eventCampaignId && this.eventCampaignId > 0)
			this.store.setEventCampaignId(this.eventCampaignId);
			
		if (this.formId && this.formId > 0) this.store.setFormId(this.formId);

		if (this.mode) {
            this.componentMode = this.mode.name;
            this.store.setComponentMode(this.mode.name, this.mode.settings);
        } else {
            this.componentMode = "blogContentEntry";
            this.store.setComponentMode("blogContentEntry", {});
        }


	},

};
</script>

<style lang="css">
</style>
