<template>
  <div>
    <modal
      name="embed-modal"
      height="auto"
      :scrollable="true"
      :reset="true"
      :max-height="modalMaxHeight"
      @before-open="beforeOpen"
    >
      <div class="form-group p-3">
        <label class="col-form-label font-weight-bold">Video/Post URL</label>
        <div v-if="error">
          <div class="alert alert-danger">
            <FontAwesomeIcon :icon="icon.exclamationTriangle" /> {{ error }}
          </div>
        </div>
        <input
          id="gf-bce-embed-url"
          ref="embedURL"
          v-model="embedURL"
          type="text"
          class="form-control border-radius-10 mb-3 gf-bce-embed-url"
          placeholder="Enter Youtube/Vimeo/Tiktok/DailyMotion/Twitter URL..."
          @keyup="onKeyup()"
        >
        <button
          type="button"
          class="btn btn-primary btn-sm mr-2 btn-embed"
          :disabled="!embedURL"
          @click="embed()"
        >
          Embed
        </button>
        <button
          type="button"
          class="btn btn-light border btn-sm btn-cancel"
          @click="$modal.hide('embed-modal'); embedURL = '';"
        >
          Cancel
        </button>
      </div>
      <div
        v-if="previewLoading"
        class="text-center"
      >
        Loading Preview...
      </div>
      <div
        v-if="previewLoaded"
        class="text-center"
      >
        <h3><strong>Preview</strong></h3>
      </div>
      <div id="preview-block">
        <editor-content
          :editor="localEditor"
          :editable="false"
        />
      </div>
    </modal>
  </div>
</template>
<script>
import { Editor, EditorContent } from "tiptap";
import { 
  HardBreak,
  Youtube,
  Vimeo,
  TikTok,
  Twitter,
  DailyMotion,
  NewLine
} from "../Content/TipTapExtensions/GfTipTapExtensions";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
export default {
  components: {
    FontAwesomeIcon,
    EditorContent
  },
  props: {
    editor: {
      type: Editor,
      default: null
    }
  },
  data() {
    return {
      localEditor: null,
      modalMaxHeight: 400,
      keyupInstance: null,
      keyupInterval: 500,
      embedURL: '',
      tiktokEmbedScriptUrl: 'https://www.tiktok.com/embed.js',
      twitterEmbedScriptUrl: 'https://platform.twitter.com/widgets.js',
      previewLoading: false,
      previewLoaded: false,
      icon: {
        exclamationTriangle: faExclamationTriangle
      },
      error: ''
    }
  },

  beforeDestroy() {
    this.localEditor.destroy();
  },

  mounted() {

    this.localEditor = new Editor({
      editable: false,
      injectCSS: false,
      extensions: [
        new HardBreak(),
        new Youtube(),
        new Vimeo(),
        new TikTok(),
        new Twitter(),
        new DailyMotion(),
        new NewLine(),
      ]
    });

    // Inject TikTok embed script programatically
    let tiktokEmbedScript = document.createElement('script')
    tiktokEmbedScript.setAttribute('src', this.tiktokEmbedScriptUrl);
    document.head.appendChild(tiktokEmbedScript);

    // Inject Twitter embed script programatically
    let twitterEmbedScript = document.createElement('script')
    twitterEmbedScript.setAttribute('src', this.twitterEmbedScriptUrl);
    twitterEmbedScript.setAttribute('charset', "utf-8");
    twitterEmbedScript.setAttribute('async', true);
    document.head.appendChild(twitterEmbedScript);
  },

  methods: {

    beforeOpen() {
      this.embedURL = '';
      this.localEditor.clearContent();
      this.previewLoaded = false;
      this.error = '';
    },

    onKeyup() {

      this.error = '';

      if (this.keyupInstance) {
        clearTimeout(this.keyupInstance);
      }

      if (this.embedURL == '') {
        return;
      }

      this.keyupInstance = setTimeout(() => {
       
        this.previewLoading = true;
        this.localEditor.clearContent();
        this.localEditor.setOptions({ editable: true });
        this.parseEmbedURL(this.localEditor, () => {
          this.localEditor.setOptions({ editable: false });
          this.previewLoading = false;
          this.previewLoaded = true;
        });
      }, this.keyupInterval);
    },

    embed() {
      this.error = '';
      this.parseEmbedURL(this.editor, () => {
        this.closeEmbedModal();
      })
    },

    parseEmbedURL(editor, cb) {
      let url = this.embedURL;

      if (url.includes('youtube.com')) {
         // Youtube
        const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
        const match = url.match(regExp);
        const youtubeID = match && match[7].length === 11 ? match[7] : false;

        editor.commands.youtube({
          type: 'Youtube',
          url: url,
          src: `https://www.youtube.com/embed/${youtubeID}`
        });

        if (typeof cb != 'undefined') {
          cb();
        }

      } else if (url.includes('vimeo.com')) {
        // Vimeo
        let vimeoURL = new URL(url)
        let vimeoID = vimeoURL.pathname.replace('/', '');

        editor.commands.vimeo({
          type: 'Vimeo',
          url: url,
          src: `https://player.vimeo.com/video/${vimeoID}`,
        });

        if (typeof cb != 'undefined') 
          cb();

      } else if (url.includes('tiktok.com')) {
        // Tiktok
        let u = new URL(url);
        let videoID = u.pathname.split("/video/")[1];

        // Fetch the data from oEmbed API
        var xhr = new XMLHttpRequest();
        xhr.addEventListener('load', () => {
          let data = JSON.parse(xhr.responseText);

          editor.commands.tiktok({
            src: url,
            type: 'Tiktok',
            cite: url,
            "data-video-id": videoID,
            title: data.title
          });

          if (typeof cb != 'undefined') 
            cb();
          
        });
        xhr.open("GET", `https://www.tiktok.com/oembed?url=${url}`);
        xhr.send();

      } else if (url.includes('twitter.com')) {
        // Twitter
        let u = new URL(url);
        let postID = u.pathname.split("/status/")[1];
        
        editor.commands.twitter({
          id: postID, 
          src: url,
          type: 'Twitter'
        });
        twttr.widgets.createTweet(postID, document.getElementById(`twitter-tweet-${postID}`))
          .then(() => {
              if (typeof cb != 'undefined') 
                cb();
          });
      } else if (url.includes('dailymotion.com')) {
        // DailyMotion
        let u = new URL(url);
        let videoID = u.pathname.split("/video/")[1];

        // Fetch the data from oEmbed API
        var xhr = new XMLHttpRequest();
        xhr.addEventListener('load', () => {
          let data = JSON.parse(xhr.responseText);
          // Create an element to store the HTML response and pull the properties from the element
          let div = document.createElement('div');
          div.innerHTML = data.embed_html;
          let iframe = div.getElementsByTagName('iframe')[0];

          editor.commands.dailymotion({
            src: iframe.src,
            type: 'Dailymotion',
          });

          if (typeof cb != 'undefined') 
            cb();
          
        });
        xhr.open('GET', `https://api.dailymotion.com/video/${videoID}?fields=embed_html`);
        xhr.send();

      } else {
        this.error = 'Unable to parse the URL.';
        this.previewLoading = false;
        this.previewLoaded = false;
      }
    },

    closeEmbedModal() {
      this.editor.commands.newline();
      this.editor.focus();
      this.embedURL = '';
      this.$modal.hide(`embed-modal`);
    }
  }
}
</script>