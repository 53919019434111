<template>
  <div id="vue-gf-information-digest">
    <div class="container-fluid px-0">
      <modal
        v-if="showAsModal && ready"
        :name="modalName"
        :adaptive="modalSettings.adaptive"
        :height="modalSettings.height"
        :delay="modalSettings.delay"
        :scrollable="modalSettings.scrollable"
      >
        <div
          class="pt-1 col-12 text-right"
        >
          <font-awesome-icon
            class="close-icon"
            :icon="closeIcon"
            @click="closeModal"
          />
        </div>
        <div class="p-2">
          <div
            v-if="userId"
            class="row"
          >
            <div class="h2 col">
              <span v-if="contentMode === ContentModes.add">
                Add New Post
              </span>
              <span v-if="contentMode === ContentModes.edit">
                Edit Post
              </span>
            </div>
          </div>
          <div class="row">
            <div class="form-group col">
              <input
                id="gf-bce-title"
                v-model="title"
                type="text"
                class="form-control border-radius-10"
                placeholder="Add a title for your post"
              >
            </div>
          </div>
          <div class="row">
            <div class="col">
              <ContentPost
                :height="200"
                :post-button-text="postButtonText"
                :default-content="content"
                @cancel="closeModal"
                @submit="onSubmit"
              />
            </div>
          </div>
        </div>
      </modal>
      <div v-if="!showAsModal && ready">
        <div
          v-if="userId"
          class="row"
        >
          <div class="h2 col">
            <span v-if="contentMode === ContentModes.add">
              Add New Post
            </span>
            <span v-if="contentMode === ContentModes.edit">
              Edit Post
            </span>
          </div>
        </div>
        <div class="row">
          <div class="form-group col">
            <input
              id="gf-bce-title"
              v-model="title"
              type="text"
              class="form-control border-radius-10"
              placeholder="Add a title for your post"
            >
          </div>
        </div>
        <div class="row">
          <div class="col">
            <ContentPost
              :height="200"
              :post-button-text="postButtonText"
              :default-content="content"
              @cancel="closeModal"
              @submit="onSubmit"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import ContentPost from "./ContentPost";
import ContentService from "../../common/ContentService";
import ContentStore from "../../common/contentStore";
import ContentModes from './ContentModes';
import GfContentComponents from "../../GfContentBase";
import VModal from "vue-js-modal";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

Vue.use(VModal);

export default {
	name: "BlogContentEntry",
	components: {
		ContentPost,
		FontAwesomeIcon,
	},

	mixins: [GfContentComponents],

	props: {
		userId: {
			type: Number,
			default: 0,
		},
		fundraisingPageId: {
			type: Number,
			default: 0,
		},
        contentMode: {
          type: String,
          default: ContentModes.add,
        },
        contentTitle: {
            type: String,
            default: '',
        },
        contentId: {
            type: Number,
            default: -1,
        },
        content: {
            type: String,
            default: 'Hi Friends ...',
        }
	},

	data() {
		return {
			ready:false,
			title: "",
			postBehalfOf: "Fundraiser",
			postBehalfOfId: 0,
			contentStore: ContentStore,
			closeIcon: faTimes,
			componentMode: "",
			showAsModal: false,
			modalSettings: {
				height: "auto",
				width: "75%",
				scrollable: true,
				adaptive: true,
				delay: 100,
			},
			modalName: "#component-modal",
            ContentModes,
		};
	},

    computed: {
        postButtonText() {
            const modes = {
                [ContentModes.add]: 'Add Post',
                [ContentModes.edit]: 'Save',
            }
            return modes[this.contentMode];
        },
    },

    watch:{
        contentTitle: {
            handler(val) {
                this.title = val
            },
            immediate: true,
        },
    },

	mounted() {
		this.$gfLogger.logInfo("Mounted BlogContentEntry");
		this.postBehalfOfId = this.fundraisingPageId;
		if (!this.loadInModal) this.showAsModal = false;
		else {
			this.showAsModal = true;
			if (this.inputDisplayIdSelector) {
				this.modalName = this.inputDisplayIdSelector;
				this.bindModalTargetButton(`#${this.modalName}`);
			}

			if (this.inputDisplayClassSelector) {
				this.modalName = this.inputDisplayClassSelector;
				this.bindModalTargetButton(`.${this.modalName}`);
			}
		}
		this.$gfLogger.logInfo("showAsModal", this.showAsModal);

		this.$root.$on("closeComponent", this.closeModal);
		this.$nextTick(()=> this.ready = true);
	},

	methods: {
		bindModalTargetButton(selector) {
			document.querySelectorAll(selector).forEach((el) => {
				el.addEventListener("click", (e) => {
					e.preventDefault();
					this.$modal.show(this.modalName);
				});
			});
		},

		closeModal() {
			this.$modal.hide(this.modalName);
            this.$emit('close');
		},

    metaContent(value) {
      const rawData = value.json;
      let newObj = [];
      const propertyTypes = ["BBCode", "Image", "Video", "Iframe"].map((itm) => {
        return itm.toLowerCase();
      });

      newObj.push({
				ContentPropertyType: "HyperText",
				Value: value.html,
				Meta: JSON.stringify(rawData),
			});

      if (rawData.content !== undefined) {
        for (const [i, item] of Object.entries(rawData.content)) {
          if (typeof item.content !== "undefined" && Array.isArray(item.content)) {
            // Loop at the item content again
            for (let idx in item.content) {
              if (typeof item.content[idx] != 'undefined') {
                // Check if the data is in our list
                if(propertyTypes.includes(item.content[idx].type)) {
                  // Push the object
                  newObj.push({
                    ContentPropertyType: item.content[idx].type.charAt(0).toUpperCase() + item.content[idx].type.slice(1),
                    Meta: JSON.stringify(item.content[idx].attrs),
                    Value: item.content[idx].attrs.src
                  });

                }
              }
            }
          } else {
            if(['tiktok','youtube', 'twitter', 'vimeo', 'dailymotion'].includes(item.type)) {
              newObj.push({
                ContentPropertyType: item.type.charAt(0).toUpperCase() + item.type.slice(1),
                Meta: JSON.stringify(item.attrs), // This is the iframe attributes
                Value: item.attrs.src
              });
            }
          }

        }
      }

			return newObj;
    },

         async onSubmit(value) {
			this.$gfLogger.logInfo("Posting blog content", value);

			let filteredContentTypes = this.metaContent(value);

			var data = {
                Id: this.contentId,
                Value: value.html,
                Meta: JSON.stringify(value.json),
				ContentCreator: {
					UserId: this.userId,
					OnBehalfOfId: this.postBehalfOfId,
					OnBehalfOf: this.postBehalfOf,
				},
				ContentPublishContext: {
					FundraisingPageId: this.fundraisingPageId,
				},
				ContentDetails: {
					Title: this.title,
					ContentType: "BlogPost",
					ContentTypeDetails: filteredContentTypes,
				},
			};

            if(this.contentMode === ContentModes.add) await this.postContent(data)
            else if (this.contentMode === ContentModes.edit) await this.updateContent(data);
            this.closeModal();
            window.onblogpostsubmit && window.onblogpostsubmit();
            this.$emit('submit');
		},

        async postContent(data){
            var response = await ContentService.publishContent(data);
            if (response.success)
              this.contentStore.addedNewBlogPost(response.data.id);
        },

        async updateContent(data){
            await ContentService.updateContent(data);
        }


	},
};
</script>
<style scoped>
  .border-radius-10 {
    border-radius: 10px !important;
  }
</style>

<style>
.vm--modal {
  height: auto !important;
  margin-bottom: 60px !important;
  margin-top: 20px !important;
}
</style>
